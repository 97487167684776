<template>
  <v-container>
    <v-card elevation="0" class="indigo" style="background: linear-gradient(45deg, #03A9F4, #0000007d);">
      <v-card-title class="">
        <span>{{ title }}</span>
      </v-card-title>
    </v-card>
    <!-- 时间线 -->
    <v-card v-for="(item, i) in toolList" :key="i" class="mt-4">
      <v-card-text class="pa-1">
        <v-row dense align="center">
          <v-col cols="12" class="font-weight-bold">{{ item.name }}</v-col>
          <!-- <v-col cols="3">
            <v-switch label="应用" dense></v-switch>
          </v-col>
          <v-col cols="1" class="text-end">
            <v-btn small color="error">删除</v-btn>
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
// let vm;
import axios from "axios"; //基于Promise的HTTP客户端
// //import qs from "qs"
import commonCfg from "../../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;
// let commonUrl = "http://192.168.1.109:8889/";
// let version = "";
export default {
  data () {
    return {
      title: "工具管理",
      showSysMsgRecord: true,
      toolList: []
    };
  },
  async created () {
    if (this.role == "sysAdmin" || this.role == "proUser") {
      await this.getToolList();
    }
  },
  props: ["role", "username"],
  methods: {
    //获得工具列表
    async getToolList () {
      let res = await axios.post(`${commonUrl}getTools${version}`);
      this.toolList = res.data;
      console.log(res);
    }
  }
};
</script>
